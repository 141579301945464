<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <option-summary v-if="showOptionSummary" :optionId="itemId" />
      </template>
    </ui-component-drawer>

    <modal-add-option
      v-if="showAddOption"
      :showModal="showAddOption"
      :onClickCancel="hideModal"
    />

    <modal-delete-option
      v-if="showDeleteOption"
      :showModal="showDeleteOption"
      :option="selectedOption"
      :onClickCancel="hideModal"
    />

    <modal-edit-option
      v-if="showEditOption"
      :showModal="showEditOption"
      :onClickCancel="hideModal"
      :isEmployee="true"
      :option="selectedOption"
    />

    <ui-section-header
      :showAdd="true"
      :addFunction="setShowAddOption"
      :addLabel="'Add option'"
    >
      <template v-slot:title>Options</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-narrow">
      <thead>
        <tr>
          <th width="100">
            {{ $t('Components.Carts.CartOptions.Table_Amount') }}
          </th>
          <th>{{ $t('Components.Carts.CartOptions.Table_Name') }}</th>
          <th width="200">Space</th>
          <th width="100">
            {{ $t('Components.Carts.CartOptions.Table_Time') }}
          </th>
          <th width="75" class="has-text-right">
            {{ $t('Components.Carts.CartOptions.Table_Price') }}
          </th>
          <th width="50" class="has-text-right">
            {{ $t('Components.Carts.CartOptions.Table_Tax') }}
          </th>
          <th width="100" class="has-text-right">
            {{ $t('Components.Carts.CartOptions.Table_Total') }}
          </th>
          <th width="50">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(option, index) in cart.Options" :key="index">
          <td>{{ option.Amount }}x</td>
          <td>
            {{ option.OptionName }}
            <a @click="setShowOptionSummary(option.OptionId)">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'info-circle']" />
              </span>
            </a>
            <div v-if="option.Id > 0">
              <notes
                :locationId="cart.LocationId"
                :typeId="36"
                :itemId="option.Id"
                :notes="option.Notes"
                :getFresh="false"
                :viewType="'basic'"
              ></notes>
            </div>
          </td>
          <td>{{ option.SpaceName }}</td>
          <td>
            <span v-if="option.TimeSelectable && option.SelectedTime > -1">{{
              option.SelectedTime | minutesToTime
            }}</span>
          </td>
          <td class="has-text-right">
            <span>{{
              option.PricePerItem
                | toCurrency(languageCulture, cart.CurrencyIso)
            }}</span>
          </td>
          <td class="has-text-right">{{ option.TaxPercentage }}%</td>
          <td class="has-text-right">
            <span>{{
              option.PriceTotal | toCurrency(languageCulture, cart.CurrencyIso)
            }}</span>
          </td>
          <td v-if="!isLocked" class="has-text-right">
            <div class="field is-grouped">
              <p class="control">
                <a @click="setShowEditOption(option, index)">
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                </a>
              </p>

              <p class="control">
                <a @click="setShowDeleteOption(option, index)">
                  <span class="icon is-small has-text-danger">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </span>
                </a>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="cart.Options.length > 0">
        <tr>
          <td colspan="6">
            {{ $t('Components.Carts.CartOptions.Table_Subtotal') }}
          </td>
          <td class="has-text-right">
            <span>{{
              total | toCurrency(languageCulture, cart.CurrencyIso)
            }}</span>
          </td>
          <td>&nbsp;</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

const ModalAddOption = () => import('@/components/Carts/ModalAddOption')
const ModalDeleteOption = () => import('@/components/Carts/ModalDeleteOption')
const ModalEditOption = () => import('@/components/Carts/ModalEditOption')
const Notes = () => import('@/components/Notes/Notes')
const OptionSummary = () => import('@/components/Options/OptionSummary')

export default {
  components: {
    'modal-add-option': ModalAddOption,
    'modal-delete-option': ModalDeleteOption,
    'modal-edit-option': ModalEditOption,
    notes: Notes,
    'option-summary': OptionSummary,
  },

  props: {
    isLocked: {
      default: false,
      type: Boolean,
    },

    languageCulture: {
      type: String,
      default: 'nl-NL',
    },
  },

  data() {
    return {
      drawerTitle: '',
      itemId: 0,
      showAddOption: false,
      showDeleteOption: false,
      showEditOption: false,
      showOptionSummary: false,
      showSideDrawer: false,
      selectedOption: null,
      selectedOptionIndex: -1,
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),

    total() {
      let total = 0.0

      if (this.cart) {
        for (let i = 0; i <= this.cart.Options.length; i++) {
          let option = this.cart.Options[i]
          if (option) {
            total = total + option.PriceTotal
          }
        }
      }

      return total
    },
  },

  created() {},

  methods: {
    cancelEditOption() {
      this.editSpace = null
    },

    setShowAddOption(option) {
      this.showAddOption = true
    },

    setShowDeleteOption(option, index) {
      this.selectedOption = option
      this.selectedOptionIndex = index

      this.showDeleteOption = true
    },

    setShowEditOption(option) {
      this.selectedOption = option

      this.showEditOption = true
    },

    hideModal() {
      this.selectedOption = null

      this.showAddOption = false
      this.showDeleteOption = false
      this.showEditOption = false
      this.selectedOptionIndex = -1
    },

    setShowOptionSummary(optionId) {
      this.itemId = optionId
      this.drawerTitle = 'Option detail'
      this.showOptionSummary = true
      this.showSideDrawer = true
    },

    hideDrawer() {
      this.itemId = 0
      this.drawerTitle = ''
      this.showOptionSummary = false
      this.showSideDrawer = false
    },
  },
}
</script>
